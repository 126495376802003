<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Certificates Settings component
 */
export default {
  page: {
    title: "Configurações de Certificados",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Configurações de Certificados",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Configurações de Certificados",
          active: true,
        },
      ],
    };
  },
  methods: {
    handleNewCad() {
      this.$router.push("/category/create");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row">
      <div class="col-xl-5">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="statusCertificate"
                        id="statusCertificateActive"
                        value="1"
                        checked=""
                      />
                      <label
                        class="form-check-label"
                        for="statusCertificateActive"
                      >
                        Ativar menu certificado (AVA)
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="statusCertificate"
                        id="statusCertificateInactive"
                        value="0"
                      />
                      <label
                        class="form-check-label"
                        for="statusCertificateInactive"
                      >
                        Desativar menu certificado (AVA)
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      onclick="setStatusCertificate()"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form
                  class="required-form"
                  method="post"
                  enctype="multipart/form-data"
                >
                  <div class="form-group">
                    <label>Modelo de certificado (frente)</label>
                    <p class="text-muted font-13">
                      N.B: Certifique-se de que o tamanho do modelo seja menor
                      que <strong>"5MB"</strong> e tenha a dimensão de
                      <strong>1123px de largura por 794px de altura</strong>.
                    </p>
                    <div class="form-group mb-2">
                      <div class="wrapper-image-preview">
                        <div class="box" style="width: 250px">
                          <div
                            class="js--image-preview"
                            style="
                              background-image: url(https://sistema.speednow.com.br/uploads/certificates/template_front.jpg?v=1696940331);
                              background-color: #f5f5f5;
                            "
                          ></div>
                          <div class="upload-options">
                            <label for="certificate_template_front" class="btn">
                              <i class="mdi mdi-camera"></i> Modelo de
                              certificado</label
                            >
                            <input
                              id="certificate_template_front"
                              style="visibility: hidden"
                              type="file"
                              class="image-upload"
                              name="certificate_template_front"
                              accept="image/*"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary">Salvar</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form
                  class="required-form"
                  method="post"
                  enctype="multipart/form-data"
                >
                  <div class="form-group">
                    <label>Modelo de certificado (verso)</label>
                    <p class="text-muted font-13">
                      N.B: Certifique-se de que o tamanho do modelo seja menor
                      que <strong>"5MB"</strong> e tenha a dimensão de
                      <strong>1123px de largura por 794px de altura</strong>.
                    </p>
                    <div class="form-group mb-2">
                      <div class="wrapper-image-preview">
                        <div class="box" style="width: 250px">
                          <div
                            class="js--image-preview"
                            style="
                              background-image: url(https://sistema.speednow.com.br/uploads/certificates/template_back.jpg?v=1696940331);
                              background-color: #f5f5f5;
                            "
                          ></div>
                          <div class="upload-options">
                            <label for="certificate_template_back" class="btn">
                              <i class="mdi mdi-camera"></i> Modelo de
                              certificado (verso)</label
                            >
                            <input
                              id="certificate_template_back"
                              style="visibility: hidden"
                              type="file"
                              class="image-upload"
                              name="certificate_template_back"
                              accept="image/*"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary">Salvar</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-7">
        <div class="row">
          <div class="col-12 card">
            <div class="card-body">
              <form
                method="post"
              >
                <div class="row">
                  <div class="d-none col-12 col-md-6 form-group">
                    <label for="width-text-certificate">Fonte (px)</label>
                    <input
                      type="range"
                      id="font-size-certificate"
                      name="font-size-certificate"
                      value="18"
                      min="1"
                      max="999"
                      class="form-control"
                    />
                  </div>
                  <div class="col-12 col-md-6 form-group">
                    <label for="margin-top-certificate"
                      >Margem vertical (27%)</label
                    >
                    <input
                      type="range"
                      id="margin-top-certificate"
                      name="margin-top-certificate"
                      min="0"
                      max="100"
                      value="27"
                      class="form-control"
                    />
                  </div>
                  <div class="col-12 col-md-6 form-group">
                    <label for="margin-left-certificate"
                      >Margem horizontal (6%)</label
                    >
                    <input
                      type="range"
                      id="margin-left-certificate"
                      name="margin-left-certificate"
                      min="0"
                      max="100"
                      value="6"
                      class="form-control"
                    />
                  </div>
                  <div class="col-12 col-md-3 form-group">
                    <label for="showContentCourse"
                      >Exibir conteúdo do curso?</label
                    >
                    <input
                      type="checkbox"
                      id="showContentCourse"
                      name="showContentCourse"
                      value="1"
                      checked="checked"
                      class="form-check"
                    />
                  </div>
                  <div class="col-12 col-md-3 form-group">
                    <label for="showStampBack">Exibir carimbo no verso?</label>
                    <input
                      type="checkbox"
                      id="showStampBack"
                      name="showStampBack"
                      value="1"
                      checked="checked"
                      class="form-check"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-3 form-group">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      id="btnSaveSettingsLayout"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-12 certificate-preview position-relative">
            <span
              id="text-certificate-container"
              style="
                z-index: 5;
                padding: 20px;
                font-size: 18px;
                top: 27%;
                left: 6%;
              "
              class="text-certificate position-absolute"
              >Certificamos que (Nome do aluno), CPF n.º XXX.XXX.XXX-XX,
              completou com sucesso o Curso de (nome do curso), documento
              registrado sob o n.º XXXXXXXXXX. Início: xx/xx/20xx Término:
              xx/xx/20xx Carga horária: x horas</span
            >
            <img
              src="https://sistema.speednow.com.br/uploads/certificates/template_front.jpg?v=1696940331"
              width="100%"
            />
          </div>
        </div>
        <div class="row my-3">
          <div class="col-12 certificate-preview">
            <span id="content-course" class="text-certificate-back">
              <span>Conteúdo programático: Nome do curso</span>
              <ul>
                <li>Tópico 1</li>
                <li>Tópico 2</li>
                <li>Tópico 3</li>
                <li>Tópico 4</li>
                <li>Tópico 5</li>
                <li>Tópico 6</li>
                <li>Tópico 7</li>
                <li>Tópico 8</li>
                <li>Tópico 9</li>
                <li>Tópico 10</li>
              </ul>
            </span>
            <img
              src="https://sistema.speednow.com.br/uploads/certificates/template_back.jpg?v=1696940331"
              width="100%"
            />
            <img
              src="https://sistema.speednow.com.br/uploads/system/carimbo.png"
              class="certificate-back-stamp"
            />
            <img
              src="https://sistema.speednow.com.br/uploads/system/qrcode.png"
              class="certificate-back-qrcode"
            />
            <span class="certificate-back-info-text"
              >Este certificado tem validade para fins curriculares e em provas
              de títulos como um certificado de atualização, aperfeiçoamento ou
              extensão profissional.</span
            >
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
